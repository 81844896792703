<template>
  <main>
    <section class="bg-primary-100 py-30 lg:py-50">
      <div class="container">
        <h2
          v-if="title"
          class="md:text-5xl text-2xl font-bold mb-30 text-primary-700 lg:mb-40"
        >
          {{ title }}
        </h2>

        <form @submit.prevent>
          <SearchInput
            v-if="!disableSearchInput"
            v-model="searchTerm"
            :completion-type="completionType"
            :completion-context="completionContext"
            :placeholder="searchInputPlaceholder"
            :label="searchInputLabel"
            :focus-on-load="focusOnLoad && !searchTerm"
          />
          <div
            v-if="numberOfFiltersAndFacets"
            class="grid grid-cols-1 gap-15 mt-20 md:grid-cols-2"
            :class="{
              'lg:grid-cols-3': numberOfFiltersAndFacets > 2 || $slots.facets,
            }"
          >
            <slot name="facets" />
            <SearchFacet
              v-for="facet in facets"
              :key="facet.id"
              v-bind="facet"
            />
            <SearchFilter
              v-for="filter in filters"
              :key="filter.id"
              :title="filterTitles[filter.id]"
              :filter="filter"
            />
          </div>
        </form>
      </div>
    </section>

    <slot name="pre-results" />

    <div v-if="hasError" class="container my-30">
      <Message
        error
        :text="
          $texts(
            'search.notAvailable',
            'Die Suchfunktion steht zur Zeit nicht zur Verüfung. Bitte versuchen Sie es später noch einmal.',
          )
        "
      />
    </div>

    <div
      v-else-if="!hideResults"
      ref="scrollToEl"
      class="container mb-25 scroll-m-10 relative"
    >
      <div v-if="data?.didYouMean" class="text-xl mt-20">
        {{ $texts('search.didYouMean', 'Meinten Sie:') }}
        <NuxtLink
          class="link font-bold"
          :to="{ query: { text: data.didYouMean } }"
        >
          {{ data.didYouMean }}
        </NuxtLink>
      </div>
      <FilterTitle
        v-if="!requireUserInput || filterCount || searchTerm"
        :filter-count="filterCount"
        :search-term="searchTerm"
        :total="data?.total"
        :title-singular="searchResultTitles?.singular"
        :title-plural="searchResultTitles?.plural"
        aria-live="polite"
        :total-documents="totalDocuments"
      />

      <div
        v-if="loaderVisible"
        class="absolute top-0 left-0 size-full bg-white/90 backdrop-blur z-30"
      >
        <Spinner class="text-primary-700 size-50 sticky top-40 mx-auto mt-40" />
      </div>

      <div v-if="hits.length || alwaysShowHits" class="relative">
        <slot name="hits" :hits="hits">
          <template v-if="groupBy && !searchTerm">
            <ol
              v-for="group in groups"
              :key="group.key"
              class="not-first:mt-30 w-full"
            >
              <li>
                <div
                  v-if="showGroupTitles"
                  class="lg:text-lg font-medium text-primary-700 py-10 lg:py-20 bg-white z-20 sticky top-sticky-top duration-250 ease-in-out transition-[top]"
                >
                  {{ group.key }}
                </div>

                <ol :class="listClass">
                  <li v-for="hit in group.items" :key="hit.id">
                    <slot :hit="hit" />
                  </li>
                </ol>
              </li>
            </ol>
          </template>
          <ol v-else :class="listClass">
            <li v-for="hit in hits" :key="hit.id" class="w-full min-w-0">
              <slot :hit="hit" />
            </li>
          </ol>
        </slot>

        <div
          v-if="!disablePagination && data?.totalPages && data.totalPages > 1"
          class="my-30 lg:my-50 bg-white py-20"
        >
          <Pagination
            :page="data.params.page + 1"
            :total-pages="data.totalPages"
            @change-page="onChangePage"
          />
        </div>
      </div>

      <div v-else-if="status === 'success' && data">
        <slot name="no-results" :search-term="searchTerm" />
      </div>
    </div>

    <slot name="after" :search-term="searchTerm" :has-error="hasError" />
  </main>
</template>

<script lang="ts">
import { groupByProperty } from '~/helpers/array'
import type {
  SearchFacet,
  SearchFulltextResponse,
} from '~/layers/site-search/helpers/types'
import type { SearchHandlerTypes } from '~/layers/site-search/types'
import { hash } from 'ohash'
</script>

<script setup lang="ts" generic="T extends keyof SearchHandlerTypes">
type SearchHit = { id: string } & SearchHandlerTypes[T]

const props = withDefaults(
  defineProps<{
    /**
     * The endpoint to use for querying the index.
     */
    endpoint: T

    /**
     * The title to display above the search input.
     */
    title?: string

    /**
     * A property of the document to group by.
     */
    groupBy?:
      | keyof SearchHandlerTypes[T]
      | { field: keyof SearchHandlerTypes[T]; hide?: boolean }

    /**
     * The number of documents to load per page.
     */
    size?: number

    /**
     * Whether a search text is required before making a query.
     */
    requireUserInput?: boolean

    /**
     * The CSS class applied to the list tag.
     */
    listClass?:
      | string
      | string[]
      | Record<string, boolean>
      | Record<string, boolean>[]

    /**
     * A key => value map for facets and filters.
     */
    filterTitles?: Record<string, string>

    /**
     * Additional query parameters to send with the API request.
     */
    queryParams?: Record<string, string | boolean | number>

    /**
     * Additional context for the completion query.
     */
    completionContext?: Record<string, string>

    /**
     * The singular and plural texts for the search results title.
     */
    searchResultTitles?: {
      singular: string
      plural: string
    }

    /**
     * The placeholder for the search input.
     */
    searchInputPlaceholder?: string

    /**
     * The screen reader label for the search input.
     */
    searchInputLabel?: string

    /**
     * Focus the search input on load.
     */
    focusOnLoad?: boolean

    /**
     * The actual total number of documents in the index.
     *
     * Because Elasticsearch only returns a count up to 10k, we can manually
     * override the total in cases where we are able to precisely tell the
     * total amount of documents that exist.
     */
    totalDocuments?: number | null

    /**
     * Disables the search input.
     */
    disableSearchInput?: boolean

    /**
     * Disables the pagination.
     */
    disablePagination?: boolean

    /**
     * Hides the results title and hits list.
     */
    hideResults?: boolean

    /**
     * If the results part should always be shown.
     */
    alwaysShowHits?: boolean
  }>(),
  {
    listClass: 'grid gap-10',
    size: 24,
    title: '',
    groupBy: undefined,
    filterTitles: () => {
      return {}
    },
    queryParams: () => {
      return {}
    },
    requireUserInput: undefined,
    completionBundles: undefined,
    completionContext: undefined,
    searchResultTitles: undefined,
    searchInputPlaceholder: undefined,
    searchInputLabel: undefined,
    totalDocuments: undefined,
    searchTerms: undefined,
    alwaysShowHits: false,
  },
)

const { $texts } = useNuxtApp()

const scrollToEl = ref<HTMLDivElement | null>(null)

const route = useRoute()
const language = useCurrentLanguage()
const searchTerm = useQueryString('text')
const page = useQueryString('page', '1')

const completionType = computed<string>(() => props.endpoint)

const query = computed(() => ({
  ...route.query,
  langcode: language.value,
  size: props.size || 16,
  page: parseInt(page.value) - 1 || undefined,
  text: searchTerm.value || undefined,
  ...props.queryParams,
}))

const filterCount = computed(
  () => Object.keys(route.query).filter((v) => v !== 'page').length,
)

const interceptor = useFetchInterceptor()

const key = computed(() => props.endpoint + ':' + hash(query.value))

const loaderVisible = ref(false)

const { data, status } =
  await useAsyncData<SearchFulltextResponse<SearchHit> | null>(
    key.value,
    () => {
      if (props.requireUserInput && !query.value.text) {
        return Promise.resolve(null)
      }

      return $fetch<unknown>('/api/search/' + props.endpoint, {
        query: query.value,
        ...interceptor,
      })
    },
    {
      watch: [query],
      deep: false,
    },
  )

let timeout: number | null = null

// Delay showing the loader when the request finishes loading under a certain
// threshold. We do this so that for fast search queries (such as our own
// Elasticsearch queries), the loader does not constantly show and hide.
watch(status, function (newStatus) {
  if (timeout) {
    window.clearTimeout(timeout)
  }

  if (newStatus === 'success' || newStatus === 'error') {
    loaderVisible.value = false

    return
  }

  timeout = window.setTimeout(function () {
    loaderVisible.value = true
  }, 200)
})

const hasError = computed<boolean>(
  () => status.value === 'error' || !!data.value?.error,
)

const facets = computed<Required<SearchFacet>[]>(() =>
  (data.value?.facets || []).map((facet) => {
    // Auto-translate IDs of months to actual months. Pure convenience since
    // we don't have the current lang in the BE and caching this would be pretty hard.
    // Also, always enable month dropdown as a workaround for Allocations search.
    if (facet.id === 'months') {
      const monthsMap = [
        $texts('months.january', 'Januar'),
        $texts('months.february', 'Februar'),
        $texts('months.march', 'März'),
        $texts('months.april', 'April'),
        $texts('months.may', 'Mai'),
        $texts('months.june', 'Juni'),
        $texts('months.july', 'Juli'),
        $texts('months.august', 'August'),
        $texts('months.september', 'September'),
        $texts('months.october', 'Oktober'),
        $texts('months.november', 'November'),
        $texts('months.december', 'Dezember'),
      ]

      facet.terms = facet.terms.map((term) => ({
        ...term,
        label: monthsMap[parseInt(term.value)],
      }))
    }

    // Handle alwaysEnabled option correctly.
    const alwaysEnabled: boolean =
      'alwaysEnabled' in facet && typeof facet.alwaysEnabled === 'boolean'
        ? facet.alwaysEnabled
        : false

    return {
      ...facet,
      label: facet.label || props.filterTitles[facet.id] || facet.id,
      alwaysEnabled,
    }
  }),
)

const hits = computed<SearchHit[]>(
  () => (data.value?.hits || []) as SearchHit[],
)

const filters = computed(() => data.value?.filters || [])

const showGroupTitles = computed(
  () => typeof props.groupBy !== 'object' || !props.groupBy.hide,
)

const groups = computed(() => {
  if (!props.groupBy || searchTerm.value) {
    return
  }

  const groupByField =
    typeof props.groupBy === 'object' ? props.groupBy.field : props.groupBy

  return groupByProperty(hits.value, groupByField)
})

const numberOfFiltersAndFacets = computed(
  () => filters.value.length + facets.value.length,
)

function onChangePage() {
  if (!scrollToEl.value) {
    return
  }

  const rect = scrollToEl.value.getBoundingClientRect()

  // Don't scroll to the element if it is already largely visible.
  if (rect.y > 50) {
    return
  }

  // Scroll to the top of the results list when the user changes the page
  // using click. We don't want to watch the page property, because that
  // would scroll everytime the page changes, even when the user uses goes
  // back/forwards in the browser history.
  scrollToEl.value.scrollIntoView()

  // Focus the first result in the list, so that when pressing "Tab", the
  // user is not focusing the next pagination button (and thus scrolling
  // all the way back down).
  const firstResult = scrollToEl.value.querySelector('ol li a')
  if (firstResult instanceof HTMLElement) {
    firstResult.focus()
  }
}

useCDNHeaders((v) => v.addTags(['nuxt:page:search']))

defineOptions({
  name: 'SearchPage',
})
</script>
